<template>
  <div>
    <h3 class="flix-html-h3" v-if="!settings || (settings && !settings.noTitle)">
      {{ $t('message.preview') }}
    </h3>
    <div ref="calendar"><flixLoader /></div>
  </div>
</template>
<script>
import { PreviewCalendar } from '@/components/assistent/previewCalendar.js'
export default {
  components: {},
  props: { settings: Object, data: Object, callback: Function, services: [Boolean, Array] },
  data () {
    return {
      variables: false
    }
  },
  methods: {

  },
  mounted () {
    this.variables = this.$getUserVariables()
    setTimeout(function () {
      new PreviewCalendar(function (f, e) { }).run(
        {
          data: {
            data: this.data,
            services: this.services
          },
          saveSettings: 'https://cal.bookingflix.com/vendor/save/' + this.$i18n.locale + '.js'
        },
        this.$refs.calendar)
    }.bind(this), 1000)
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
